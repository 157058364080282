import { useObserver } from 'mobx-react'
import React from 'react'
import { Box, Flex } from 'reflexbox'
import scrollIntoView from 'scroll-into-view'
import { Link } from 'gatsby'

import MobileNav from 'src/components/MobileNav/MobileNav'
import useStore from 'src/stores/useStore'

import * as styles from './Header.module.scss'
import LinkComponent from 'src/storyblok/Components/LinkComponent/LinkComponent'

const Header = ({ blok }: any) => {
  const { ui } = useStore()
  const { links }: any = blok ? (blok.content ? blok.content : blok) : {}

  return useObserver(() => (
    <React.Fragment>
      <Flex
        paddingX={[7, null, null, 16]}
        alignItems={'center'}
        justifyContent="space-between"
        className={styles.wrapper}
        height={['64px']}
        as="header"
      >
        <Link to="/">
          <svg
            width="186"
            height="28"
            viewBox="0 0 186 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M28.2621 26.867H19.3056V25.9693H20.379C21.7208 25.9693 22.347 25.5485 22.347 24.2301C22.347 23.9327 22.319 23.54 22.2855 23.1529L20.9101 9.10418L14.559 26.867H13.0383L6.62 9.34543L5.30616 22.676C5.24466 23.1529 5.24466 23.5175 5.24466 23.8766C5.24466 25.5541 6.08328 25.9749 7.60398 25.9749H8.40906V26.8726H0.498047V25.9749H0.917359C2.79588 25.9749 3.39409 24.836 3.573 23.0406L4.9148 10.0972C4.9763 9.6484 4.9763 9.28933 4.9763 8.98636C4.9763 7.63984 4.41162 7.03952 2.91887 7.03952H2.25916V6.14184H8.34756L14.408 22.6479L20.3511 6.13623H26.6184V7.03391H25.9642C24.41 7.03391 23.7559 7.54446 23.7559 8.9527C23.7559 9.37348 23.7838 9.73256 23.8453 10.2431L25.1312 22.8555C25.3716 25.2231 25.9083 25.9693 27.8204 25.9693H28.2677V26.867H28.2621Z"
              fill="white"
            />
            <path
              d="M45.5828 2.54386C45.5828 1.52275 46.3879 0.714844 47.3719 0.714844C48.3894 0.714844 49.2224 1.52275 49.2224 2.54386C49.2224 3.56497 48.3838 4.33922 47.3719 4.33922C46.3935 4.33922 45.5828 3.55936 45.5828 2.54386ZM45.0181 17.1311L42.0606 8.80517L38.9242 17.1311H45.0181ZM36.0896 2.54386C36.0896 1.52275 36.8947 0.714844 37.9122 0.714844C38.9297 0.714844 39.7348 1.52275 39.7348 2.54386C39.7348 3.56497 38.9297 4.33922 37.9122 4.33922C36.9003 4.33922 36.0896 3.55936 36.0896 2.54386ZM53.5274 25.9677V26.8654H44.5094V25.9677H45.7058C46.9023 25.9677 47.439 25.4291 47.439 24.5594C47.439 24.1723 47.3495 23.7515 47.1706 23.241L45.499 18.6291H38.393L36.7214 23.0951C36.5145 23.6618 36.3915 24.1723 36.3915 24.565C36.3915 25.5244 37.1351 25.9733 38.27 25.9733H39.4329V26.871H31.6729V25.9733C33.3166 25.9733 34.0881 25.4347 35.0162 23.039L41.5239 6.13458H43.8497L50.24 23.1512C50.9891 25.1598 51.9731 25.9396 53.5274 25.9677Z"
              fill="white"
            />
            <path
              d="M57.2507 25.786L56.563 20.1531H57.7594C58.2346 23.1211 60.1747 25.7243 63.518 25.7243C65.8438 25.7243 67.549 24.3441 67.549 22.0999C67.549 20.1251 66.1457 19.2218 64.1777 18.4475L61.4885 17.3984C58.654 16.2875 56.9488 14.5819 56.9488 11.7654C56.9488 8.16909 60.1411 5.74536 64.1721 5.74536C66.5314 5.74536 68.382 6.28397 69.5449 6.82258V11.9169H68.4379C68.3764 9.36974 66.7942 7.18165 63.7528 7.18165C61.1866 7.18165 59.364 8.7077 59.364 10.778C59.364 12.5172 60.4374 13.5327 62.411 14.3126L65.1002 15.3617C68.354 16.6522 70.0537 18.2063 70.0537 21.1125C70.0537 24.6752 67.0066 27.284 63.0707 27.284C60.5325 27.284 58.5645 26.594 57.2507 25.786Z"
              fill="white"
            />
            <path
              d="M79.8944 25.9658C82.0413 25.9658 82.4047 24.9783 82.4047 23.542V7.63066H80.1348C77.5351 7.63066 76.0759 8.16927 75.774 11.4346L75.6845 12.3323H74.5496L74.8179 5.95312C75.2652 5.98118 75.7125 6.04289 76.2827 6.07095C76.8194 6.099 77.4456 6.13266 78.1948 6.13266H89.5665C91.1208 6.13266 92.0712 6.04289 92.9098 5.95312L93.1782 12.3323H92.0433L91.9538 11.4009C91.6239 8.13561 90.1647 7.62505 87.593 7.62505H85.3231V23.5308C85.3231 24.9671 85.6809 25.9545 87.8334 25.9545H88.521V26.8522H79.2347V25.9545H79.8944V25.9658Z"
              fill="white"
            />
            <path
              d="M113.182 26.867H97.7174V25.9693H98.2262C100.284 25.9693 100.524 25.0436 100.524 23.6354V9.37348C100.524 7.96525 100.284 7.03391 98.2262 7.03391H97.7174V6.13623H113.897V11.4101H112.852C112.824 9.88404 112.432 8.92464 111.717 8.35237C110.973 7.78572 109.894 7.57252 108.463 7.57252H103.42V15.7807H107.568C109.777 15.7807 110.554 15.1803 110.582 12.9025H111.566V20.0615H110.582C110.52 17.907 109.715 17.217 107.568 17.217H103.42V23.5961C103.42 24.735 103.957 25.2736 105.064 25.2736H108.049C111.007 25.2736 112.321 24.1347 113.45 21.2285H114.557L113.182 26.867Z"
              fill="white"
            />
            <path
              d="M128.089 15.8409C130.923 15.8409 132.807 14.4327 132.807 11.4367C132.807 9.01295 131.253 7.45324 128.536 7.45324H127.429C126.624 7.45324 125.606 7.69449 125.606 9.13077V15.8409H128.089ZM139.164 25.9679V26.8655H135.402L127.373 17.1594H125.612V23.69C125.612 25.3675 125.97 25.9679 128.027 25.9679H128.536V26.8655H119.792V25.9679H120.48C122.481 25.9679 122.688 25.0085 122.688 23.69V9.2542C122.688 7.93574 122.481 7.03806 120.48 7.03806H119.792V6.14038H129.464C133.193 6.14038 136.033 7.90769 136.033 11.3525C136.033 14.2307 134.032 16.2954 130.482 16.9518L136.274 23.3309C137.643 24.8626 138.504 25.7266 139.164 25.9679Z"
              fill="white"
            />
            <path
              d="M156.331 21.328C156.331 18.6013 154.452 16.8957 151.255 16.8957H148.565V23.4544C148.565 25.0702 149.342 25.4629 150.712 25.4629H151.847C154.536 25.4573 156.331 24.021 156.331 21.328ZM155.733 11.4703C155.733 8.83342 153.854 7.51495 151.014 7.51495H148.565V15.4819H151.551C154.089 15.4819 155.733 13.9839 155.733 11.4703ZM143.522 25.9679C145.462 25.9679 145.669 25.0702 145.669 23.7517V9.2542C145.669 7.93574 145.462 7.03806 143.433 7.03806H142.868V6.14038H152.361C156.152 6.14038 158.752 8.02551 158.752 11.1113C158.752 14.1073 156.303 15.9026 153.262 16.1439C156.873 16.1719 159.529 18.0627 159.529 21.2663C159.529 24.7111 156.694 26.8712 152.155 26.8712H142.868V25.9735H143.522V25.9679Z"
              fill="white"
            />
            <path
              d="M181.799 16.5043C181.799 10.9948 178.931 7.27505 174.693 7.27505C170.422 7.27505 167.621 11.0509 167.621 16.5043C167.621 22.0138 170.489 25.6999 174.693 25.6999C178.965 25.6999 181.799 21.924 181.799 16.5043ZM164.216 16.5043C164.216 10.4843 169.024 5.72095 174.693 5.72095C180.368 5.72095 185.17 10.4843 185.17 16.5043C185.17 22.5244 180.485 27.2877 174.693 27.2877C169.019 27.2877 164.216 22.5244 164.216 16.5043Z"
              fill="white"
            />
          </svg>
        </Link>
        <Box
          aria-label="Menu Button"
          className={
            ui.activeMenu === 'nav'
              ? [styles.hamburger, styles.active, 'hideDesktop'].join(' ')
              : [styles.hamburger, 'hideDesktop'].join(' ')
          }
          as="button"
          onClick={() => {
            if (ui.activeMenu === 'nav') {
              ui.setActiveMenu('')
            } else {
              ui.setActiveMenu('nav')
            }
          }}
        >
          <div />
          <div />
        </Box>
        <Flex className="hideMobile">
          {links &&
            links.length > 0 &&
            links.map((link: any, index: number) => (
              <Box
                marginRight={index === links.length - 1 ? 0 : '64px'}
                className={styles.link}
                key={link._uid}
              >
                <LinkComponent link={link.link}>{link.title}</LinkComponent>
              </Box>
            ))}
        </Flex>
      </Flex>
      <MobileNav links={links} />
    </React.Fragment>
  ))
}

export default Header

import * as React from 'react'
import { Link } from 'gatsby'

import { Props } from './types'

const LinkComponent = ({
  link,
  children,
  onClick,
  className,
  title,
  lang,
  onMouseEnter,
  onMouseLeave,
  ariaLabel,
}: Props): JSX.Element => {
  if (!link) {
    return <React.Fragment>{children}</React.Fragment>
  }

  let { cached_url, anchor } = link
  const isDownload = cached_url.substr(0, 9) === 'data:text'
  const isExternal =
    cached_url.substr(0, 4) === 'http' ||
    cached_url.substr(0, 7) === 'mailto:' ||
    isDownload

  if (cached_url === 'home') {
    cached_url = ''
  }
  // Check for missing "/" in relative urls
  if (!isExternal && !cached_url.startsWith('/')) {
    cached_url = `/${cached_url}`
  }

  if (anchor) {
    cached_url = `${cached_url}#${anchor}`
  }

  return isExternal ? (
    <a
      aria-label={ariaLabel}
      className={className}
      href={cached_url}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      target="_blank"
      rel="noopener noreferrer"
      title={title}
      download={isDownload}
    >
      {children}
    </a>
  ) : (
    <Link
      aria-label={ariaLabel}
      className={className}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      title={title}
      to={cached_url}
    >
      {children}
    </Link>
  )
}

export default LinkComponent
